@use "./theme";
@use "./consts";
@use "./global" as *;

.MyWork {
	@include page-section;

	.works  {
		margin-bottom: 1.75em;

		& > section:not(:last-child) {
			margin-bottom: 1em;
		}
	}

	details:not([open]) summary::after {content: "…";}
}
