@use "./theme";
@use "./global" as *;
@use "./consts";

.Contact {
	@include page-section;

	text-align: center;

	a #{$FA} {margin-right: 0.5em;}

	.socials {
		a {
			#{$FA} {margin: 0;}
			display: inline-block;
			margin: 0 0.25em;
			font-size: 2em;
			transition: color consts.$fade-time;

			&:first-child {margin-left: 0;}
			&:last-child {margin-right: 0;}

			@include hover {
				text-decoration: none;
				color: white;
			}
		}
	}
}
