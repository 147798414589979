@use "sass:selector";
@use "./theme";
@use "./consts";

// perhaps extract the following to package

$FA: ":global(svg.faIcon)";

@mixin hover {
	@at-root {
		#{selector.nest(":global(body.canHover)", selector.append(&, ":hover"))} {
			@content;
		}
	}
}

@mixin h {
	h1, h2, h3, h4, h5, h6 {
		@content;
	}
}

// @mixin clear-after {
// 	&::after {
// 		content: "";
// 		clear: both;
// 		display: block;
// 	}
// }

// end package

@mixin buttonLink {
	display: inline-block;
	background-color: theme.$accent;
	border-radius: consts.$border-radius;
	transition: background-color consts.$fade-time;
	padding: 0.5em 0.875em;
	text-decoration: none;
	color: unset;

	@include hover {
		background-color: lighten(theme.$accent, 10%);
		text-decoration: none;
		color: unset;
	}

	#{$FA} {margin-right: 0.5em;}
}

@mixin page-section {
	$med: 10%;
	$lg: 20%;

	margin: 3em consts.$page-margin;
	padding: 0 1em;
	scroll-margin-top: calc(5.25em + 6px);

	@media (min-width: consts.$break-med) {
		margin-left: calc(consts.$page-margin + $med);
		margin-right: calc(consts.$page-margin + $med);
	}

	@media (min-width: consts.$break-lg) {
		margin-left: calc(consts.$page-margin + $lg);
		margin-right: calc(consts.$page-margin + $lg);
	}
}

@mixin border-sh($color) {
	content: "";
	display: block;
	height: 0;
	border-radius: 2px;
	border-top: 4px solid $color;
}
