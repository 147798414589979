@use "./theme";
@use "./global" as *;

html {
	overflow-y: scroll;
	scroll-behavior: smooth;
}

body {
	font-family: "Roboto", sans-serif;
	background-color: theme.$bg;
	color: theme.$fg;
}

a {
	color: theme.$accent;
	text-decoration: none;

	@include hover {text-decoration: underline;}
}

@include h {
	font-family: "Comfortaa", sans-serif;
	text-align: center;
}

h1 {font-weight: bold;}
h2 {font-weight: normal;}

address {font-style: unset;}

details {
	summary {
		@extend a;
		cursor: pointer;
	}
}
