@use "./theme";
@use "./global" as *;

.About {
	@include page-section;
	
	text-align: center;

	h1 {
		margin-bottom: 0.25em;
	}

	h2 {
		font-weight: 300;
		margin-top: 0.25em;
		color: theme.$dim-text;
	}
}
