@use "./theme";
@use "./consts";
@use "./global" as *;

.Nav {
	ul {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
		align-items: center;

		a {
			display: block;
			padding: 0.5em 0.875em;
			color: unset;
			text-decoration: none;
			transition: color consts.$fade-time;

			@include hover {
				color: theme.$accent;
				text-decoration: none;
			}
		}

		li:not(.resumeLink) {
			display: none;

			@media (min-width: consts.$break-sm) {
				display: unset;
			}
		}

		li.resumeLink a {
			@include buttonLink;

			margin-left: 1em;

			#{$FA} {
				margin-right: 0;
				margin-left: 0.5em;
			}
		}
	}
}
