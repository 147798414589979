@use "./theme";
@use "./global" as *;

.Footer {
	@include page-section;
	margin-top: 0;
	margin-bottom: 0;
	// padding: 0;

	text-align: center;

	&::before {@include border-sh(theme.$box-bg);}

	.inside {
		.kindaHeader {
			margin-bottom: 0.5em;
		}

		.tech {
			margin-top: 0.5em;
			color: theme.$dim-text;
			font-style: italic;
		}

		.copyright {
			color: theme.$dim-text;
		}

		margin: 1em;
	}
}
