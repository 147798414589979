@use "./theme";
@use "./consts";
@use "./global" as *;

.Work {
	background-color: theme.$box-bg;
	border-radius: consts.$border-radius;
	padding: 1em;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1em;

	img {
		display: block;
		max-width: 100%;
		max-height: 12em;
	}

	.content {
		width: 100%;

		.headings {
			margin-bottom: 1em;

			@include h {
				text-align: left;
				margin: 0;
			}

			h4 {
				// font-style: italic;
				color: theme.$dim-text;
				margin-top: 0.5em;
			}
		}

		ul {
			padding-left: 1em;
		}

		.tech {
			color: theme.$dim-text;
			font-style: italic;
		}

		.opts {
			display: flex;

			a {
				@include buttonLink;
			}
		}
	}

	&.project {
		img {
			border-radius: consts.$border-radius;
		}

		@media (min-width: consts.$break-med) {
			flex-direction: row;
			align-items: flex-start;

			img {
				order: 1;
				max-width: 50%;
			}

			.content {
				width: unset;
				flex-grow: 1;
			}
		}
	}
}
